import { ADD_PEER_STREAM, ADD_PEER_NAME , REMOVE_PEER_STREAM, ADD_ALL_PEERS, REMOVE_ALL_PEER_STREAM,ADD_MUTE, MUTE_ALL_PEER } from "../reducers/peersActions";
import { IPeer } from "../types/peer";

export type PeerState = Record<string, { stream?: MediaStream, userName?:string, peerId?:string, role?:string, mute?:boolean }>;
type PeerAction = 
    | {
        type: typeof ADD_PEER_STREAM;
        payload: { peerId: string; stream: MediaStream };
      } 
    | {
        type: typeof REMOVE_PEER_STREAM;
        payload: { peerId: string };
      }
    | {
        type: typeof REMOVE_ALL_PEER_STREAM;
    }
    | {
        type: typeof ADD_PEER_NAME;
        payload: { peerId: string, userName:string, role:string, mute:boolean };
    }
    | {
        type: typeof ADD_ALL_PEERS;
        payload: { peers: Record<string,IPeer> };
    }| {
        type: typeof ADD_MUTE;
        payload: { peerId: string,mute:boolean };
    }| {
        type: typeof MUTE_ALL_PEER;
        payload: { mute:boolean };
    };

export const peersReducer = (state:PeerState, action:PeerAction)=> {
    switch (action.type){
        case ADD_PEER_STREAM:
            return {
                ...state,
                [action.payload.peerId]: {
                    ...state[action.payload.peerId],
                    peerId: action.payload.peerId,
                    stream: action.payload.stream,
                },
            };
        case ADD_PEER_NAME:
            return {
                ...state,
                [action.payload.peerId]: {
                    ...state[action.payload.peerId],
                    userName: action.payload.userName,
                    role: action.payload.role,
                    mute: action.payload.mute
                },
            };
        case ADD_MUTE:
            return {
                ...state,
                [action.payload.peerId]: {
                    ...state[action.payload.peerId],
                    mute: action.payload.mute
                },
            };
        case MUTE_ALL_PEER:
            const updatedState = { ...state };
            for (const peerId in updatedState) {
              updatedState[peerId] = {
                ...updatedState[peerId],
                mute: action.payload.mute,
              };
            }
            return updatedState;
        case REMOVE_PEER_STREAM:
            const { peerId } = action.payload;
            const { [peerId]: removedPeer, ...restPeers } = state;
            if (!removedPeer) {
                return state;
            }
            return restPeers;
        case ADD_ALL_PEERS:
            return {
                ...state,...action.payload.peers
            }
        case REMOVE_ALL_PEER_STREAM:
                return {};
        default:
            return {...state};
    }
}