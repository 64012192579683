import { createContext, useEffect, useState, useReducer, useContext } from 'react';

import {useNavigate} from 'react-router-dom';
import Peer from 'peerjs';
import { peersReducer, PeerState } from '../reducers/peersReducer';
import { addAllPeersAction, addMuteAllPeerAction, addPeerMuteAction, addPeerNameAction, addPeerStreamAction, removeAllPeerStreamAction, removePeerStreamAction } from '../reducers/peersActions';
import { UserContext } from './UserContext';
import { ws } from '../ws';
import { IPeer } from '../types/peer';
import collect from 'collect.js';
import axios from "axios";
import { toast } from 'react-toastify';


interface RoomValue {
    stream?: MediaStream;
    screenStream?: MediaStream;
    newDeviceStream?: MediaStream;
    peers: PeerState;
    videoDevices: string[];
    audioOutputDevices: string[];
    audioInputDevices: string[];
    shareScreen: () => void;
    muteAudio: () => void;
    exitRoom: (roomId:string) => void;
    closeClass: (roomId:string) => void;
    switchDevices: (videoId:string,audioId:string) => void;
    roomId: string;
    setRoomId: (id: string) => void;
    screenSharingId: string;
    connectionLost:boolean;
    soundOn:boolean;
    toggleSidebar: () => void;
    optionSidebar:boolean;
    classStatus:boolean;
    alertShow:boolean;
    alertNote:string;
    isLogin:boolean;
    getAuth: (token:string) => void;
    setAlertShow: (show:boolean) => void;
    setAlertNote: (note:string) => void;
    muteAll : boolean;
    setMuteAll : (sound:boolean) => void;
    setTeacherJoin : (join:boolean) => void;
    teacherJoin:boolean;
    getDevices:(initial:boolean)=>void;
    setInitialVideoDevice:(deviceId:string)=>void;
    initializeStream:(join:boolean)=>void;
    reinitializeStream: () => void;
}

export const RoomContext = createContext<RoomValue>({
    peers: {},
    videoDevices :[],
    audioOutputDevices :[],
    audioInputDevices :[],
    shareScreen: () => {},
    muteAudio: () => {},
    exitRoom: (roomId) => {},
    closeClass: (roomId) => {},
    switchDevices: (videoId,audioId) => {},
    setRoomId: (id) => {},
    screenSharingId: "",
    roomId: "",
    connectionLost:false,
    soundOn:true,
    toggleSidebar: () => {},
    optionSidebar:false,
    classStatus:false,
    alertShow:false,
    alertNote:"",
    isLogin:false,
    getAuth: (token) => {},
    setAlertShow:(show) =>{},
    setAlertNote:(note) =>{},
    muteAll:false,
    setMuteAll: (sound) => {},
    setTeacherJoin: (join) => {},
    teacherJoin:false,
    getDevices:(initial)=>{},
    setInitialVideoDevice:(deviceId)=>{},
    initializeStream:(join)=>{},
    reinitializeStream : () => {}
});

if(!!window.Cypress){
    window.Peer =  Peer;
}

interface Props {
    children: React.ReactNode;
}

export const RoomProvider: React.FunctionComponent<Props> = ({children}) => {
    const navigate = useNavigate();
    const [me,setMe] = useState<Peer>();
    const {userName,userId,role,setUserData,setClassInfo} = useContext(UserContext)
    const [stream,setStream] = useState<MediaStream>();
    const [screenStream,setScreenStream] = useState<MediaStream>();
    const [peers, dispatch] = useReducer(peersReducer ,{});
    const [videoDevices, setVideoDevices] = useState([]);
    const [audioOutputDevices, setAudioOutputDevices] = useState([]);
    const [audioInputDevices, setAudioInputDevices] = useState([]);
    const [screenSharingId,setScreenSharingId] = useState<string>("");
    const [onCam,setOnCam] = useState<boolean>();
    const [recon,setRecon] = useState<boolean>(false);
    const [optionSidebar,setOptionSidebar] = useState<boolean>(false);
    let   [soundOn,setSoundOn] = useState<boolean>(true);
    const [connectionLost,setConnectionLost] = useState<boolean>(false);
    const [roomId,setRoomId] = useState<string>("");
    const [newDeviceStream,setNewDeviceStream] = useState<MediaStream>();
    const [classStatus,setClassStatus] = useState<boolean>(false);
    const [alertShow,setAlertShow] = useState<boolean>(false)
    const [alertNote,setAlertNote] = useState<string>("")
    const [isLogin,setIsLogin] = useState<boolean>(false)
    const [muteAll,setMuteAll] = useState<boolean>(true);
    const [teacherJoin,setTeacherJoin] = useState<boolean>(false);
    const [recentAudio,setRecentAudio] = useState<string>("");
    const [initializeVideoDevice,setInitializeVideoDevice] = useState<string>("");
    const [initialStream,setInitialStream] = useState<MediaStream>();
    const enterRoom = ({roomId} : { roomId: "string" }) => {
        navigate(`/room/${roomId}`)
    };
    const getUsers = ({participants} : {participants:Record<string,IPeer>})=>{
        dispatch(addAllPeersAction(participants))
    }

    const removePeer = ( peerId:string ) => {
        dispatch(removePeerStreamAction(peerId))

    }
    const shareScreen = () => {
        if(onCam){
            navigator.mediaDevices.getUserMedia({video:true,audio:true}).then(switchStream)
        }else{
            navigator.mediaDevices.getDisplayMedia({}).then((stream)=>{
                switchStream(stream)
                setScreenStream(stream)
            })
        }
    }
    const switchStream = (stream:MediaStream) => {
        if(onCam){
            setScreenSharingId("")
            setOnCam(false)
        }else{
            setOnCam(true)
            setScreenSharingId(me?.id||"");
        }
        
        Object.values(me?.connections).forEach((connection:any)=>{
            const videoTrack:any = stream?.getTracks().find(track=>track.kind === 'video');
            // console.log(connection[0].peerConnection.getSenders()[1]);
            connection[0].peerConnection.getSenders().find((sender:any)=>sender.track.kind === "video").replaceTrack(videoTrack).catch((err:any)=>{
                console.log(err);
            })
        })
    }

    const switchDevices = async (videoId:string,audioId:string) => {
        try {
            const OwnVideoElement = document.getElementById("video") as HTMLVideoElement;
            const oldVideoTrack = (newDeviceStream)?newDeviceStream:stream;
            if (oldVideoTrack) {
                oldVideoTrack.getVideoTracks()[0].stop()
                console.log(oldVideoTrack)
            }
            setRecentAudio(audioId)
            const constraints = {video: {   deviceId: videoId, width: { exact: 1080 },
                                            height: { exact: 720 },
                                            frameRate: { max: 30 }
                                        },  audio: false};
            
            const newStream = await navigator.mediaDevices.getUserMedia(constraints);
            OwnVideoElement.srcObject = newStream;
            const videoTrack:any = newStream?.getTracks().find(track=>track.kind === 'video');
            // const audioTrack:any = newStream?.getTracks().find(track=>track.kind === 'audio');
        // if(soundOn === false){
        //     audioTrack.enabled = soundOn;
        // }
        setNewDeviceStream(newStream)
            Object.values(me?.connections).forEach((connection:any)=>{
                let data:any = connection[0];
                if(connection.length > 1){
                    data = connection[connection.length - 1];
                }
            
                data.peerConnection.getSenders().find((sender:any)=>sender.track.kind === "video").replaceTrack(videoTrack).catch((err:any)=>{
                    console.log(err);
                })

                // data.peerConnection.getSenders().find((sender:any)=>sender.track.kind === "audio").replaceTrack(audioTrack).catch((err:any)=>{
                //     console.log(err);
                // }) 
                toast.success("Device Changed")
            })

        } catch (error) {
            toast.error('failed load device')
            console.log('Error switching devices:', error);
        }
    }
    
    const muteAudio = async () => {
        try{
            navigator.mediaDevices.getUserMedia({audio: (recentAudio)?{deviceId: recentAudio}:true}).then((stream)=>{
                const audioTrack = stream?.getAudioTracks()[0];
                audioTrack.enabled = !soundOn;
                setSoundOn(!soundOn)
                soundOn = !soundOn;
                Object.values(me?.connections).forEach((connection:any)=>{
                    let data:any = connection[0];
                    if(connection.length > 1){
                        data = connection[connection.length - 1];
                    }
                    data.peerConnection.getSenders().find((sender:any)=>sender.track.kind === "audio").replaceTrack(audioTrack).catch((err:any)=>{
                        console.log(err);
                    })
                })
            })
        }catch(error){
            console.log(error)
            console.log('failed to get local stream')
        }
    }
    
    const stopShareScreen = ()=>{
        setScreenSharingId("")
    }

    const toggleSidebar = ()=>{
        getDevices(false)
        setOptionSidebar(!optionSidebar)
    }

    const getDevices = async(initial:boolean)=>{
        try {
            navigator.mediaDevices.enumerateDevices().then((res)=>{
                var audioOutput = collect(res).where('kind','audiooutput').map((item,index)=>{
                    var data = {
                        label:"",
                        deviceId:""
                    }
                    data.label = item.label
                    data.deviceId = item.deviceId
                   return data
                }).all()
                var audioInput = collect(res).where('kind','audioinput').map((item,index)=>{
                    var data = {
                        label:"",
                        deviceId:""
                    }
                    data.label = item.label
                    data.deviceId = item.deviceId
                   return data
                }).all()
                var videoInput = collect(res).where('kind','videoinput').map((item,index)=>{
                    var data = {
                        label:"",
                        deviceId:""
                    }
                    data.label = item.label
                    data.deviceId = item.deviceId
                   return data
                }).all()
                setVideoDevices(videoInput as any)
                setAudioOutputDevices(audioOutput as any)
                setAudioInputDevices(audioInput as any)
                if(initial){
                    toast.success("Success get all device info")
                }
            }).catch((err)=>{
                toast.error('failed load device')
                console.log(err)
            });
        } catch (error) {
            console.log(error)
            console.log('failed to get local devices')
        }
    }

    const fetchElearning = (elearnings:any)=>{
        console.log(elearnings)
    }

    const exitRoom = (roomId:string) =>{
        dispatch(removeAllPeerStreamAction())
        ws.emit('exit-room',{roomId:roomId,peerId:me?.id})
        //redirect back to elesson portal
        window.location.href = process.env.REACT_APP_MMLIVE as string
    }

    const closeClass = (roomId:string)=>{
        dispatch(removeAllPeerStreamAction())
        ws.emit('close-class',{roomId:roomId,peerId:me?.id})
    }

    const roomChecked = (classData:any)=>{
        if(!classData.status){
            setAlertShow(true)
            setAlertNote("Class not found!")
        }else{ 
            if(classData.classData.status === "close"){
                setAlertShow(true)
                setAlertNote("Class closed by teacher")
            }else{
                setClassStatus(classData.status)
                setClassInfo(classData)
            }
        }
    }

    const getAuth = async (token:string)=>{
        const url = process.env.REACT_APP_AUTH_API as string
        await axios.get( url ,{ headers: { 'Authorization': token} }).then(function(response){
            setUserData(response.data.data)
            setIsLogin(true)
        }).catch(function(error){
            console.log(error.response.data.message)
            setAlertShow(true)
            setAlertNote(error.response.data.message)
        })
    }

    const refreshRoom = ()=>{
        window.location.reload();
    }

    const muteChanged = (data:any)=>{
        dispatch(addPeerMuteAction(data.peerId,data.mute))
    }

    const allStudentMuted = (mute:boolean)=>{
        dispatch(addMuteAllPeerAction(mute))
        console.log('mutedall')
    }

    const setInitialVideoDevice = (deviceId:string) =>{
        setInitializeVideoDevice(deviceId)
    }

    const initializeStream = async (join:boolean) => {
        try{
            navigator.mediaDevices.getUserMedia({video:{
                deviceId: initializeVideoDevice,
                width: { exact: 1080 },
                height: { exact: 720 },
                frameRate: { max: 30 },
            }, audio:false }).then(async (stream)=>{
                // stream.getAudioTracks()[0].enabled = false;
                if(role === "Student" && join){
                    await setStream(stream)
                    toast.success('Device Loaded Successfully')
                }else{
                    await setInitialStream(stream)
                    getDevices(false)
                }
            }).catch((e)=>{
                toast.error('Your Device is in Use , Please Select another device')
                console.log(e)
            })
        }catch(error){
            console.log(error)
            console.log('failed to get local stream')
        }
    }

    const reinitializeStream = async () => {
        console.log("reconnect")
        setRecon(true)
        stream?.getTracks().forEach(function(track) {
            track.stop();
        });
        try{
            stream?.getTracks().forEach(function(track) {
                track.stop();
            });
            navigator.mediaDevices.getUserMedia({video:{
                deviceId: initializeVideoDevice,
                width: { exact: 1080 },
                height: { exact: 720 },
                frameRate: { max: 30 },
            }, audio:true }).then(async (stream)=>{
                await setStream(stream)
                const videoTrack:any = stream.getTracks().find(track=>track.kind === 'video');
                
                Object.values(me?.connections).forEach((connection:any)=>{
                    let data:any = connection[0];
                    if(connection.length > 1){
                        data = connection[connection.length - 1];
                    }
                    data.peerConnection.getSenders().find((sender:any)=>sender.track.kind === "video").replaceTrack(videoTrack).catch((err:any)=>{
                        console.log(err);
                    })
                })
            })
        }catch(error){
            console.log(error)
            console.log('failed to get local stream')
        }
    }

    useEffect(() =>{
        ws.emit("change-name",{peerId: userId, userName, roomId})
    },[userName, userId, roomId])

    useEffect(()=>{
        if(role === "Student"){
            getDevices(true)
        }
    },[role])

    useEffect(()=>{
        const peer2 = (process.env.REACT_APP_PEER_SECURE === "true")? 
        new Peer(userId,{
                    host: process.env.REACT_APP_PEER_HOST as any, 
                    port: process.env.REACT_APP_PEER_PORT as any, 
                    path: process.env.REACT_APP_PEER_PATH as any,
                    secure: true,
                    config: {
                        iceServers: [
                            {
                            urls: process.env.REACT_APP_STUN_SERVER as string,
                            },
                            {
                            urls: process.env.REACT_APP_TURN_SERVER as string,
                            username: process.env.REACT_APP_TURN_USERNAME as string,
                            credential: process.env.REACT_APP_TURN_CREDENTIALS as string,
                            }
                        ]
                    }
                })
        : 
        new Peer(userId,{
            host: process.env.REACT_APP_PEER_HOST as any,
            port: process.env.REACT_APP_PEER_PORT as any,
            path: process.env.REACT_APP_PEER_PATH as any,
        })
        setMe(peer2)

        

        // initializeStream()

        ws.on("room-created",enterRoom)
        ws.on("get-users",getUsers)
        ws.on("user-disconnected",removePeer)
        ws.on("user-started-sharing", (peerId) => setScreenSharingId(peerId))
        ws.on("user-stopped-sharing", stopShareScreen)
        ws.on("data-fetched", fetchElearning)
        ws.on("room-checked", roomChecked)
        ws.on("refresh-room",refreshRoom)
        ws.on("mute-changed",muteChanged)
        ws.on("all-student-muted",allStudentMuted)
        return ()=>{
            ws.off("room-created")
            ws.off("get-users")
            ws.off("user-disconnected")
            ws.off("user-started-sharing")
            ws.off("user-stopped-sharing")
            // ws.off("user-joined")
            ws.off("name-changed")
            ws.off("data-fetched")
            ws.off("room-checked")
            ws.off("refresh-room")
            ws.off("mute-changed")
            ws.off("all-student-muted")
            me?.disconnect()
        }
    },[])

    useEffect(()=>{
        if(screenSharingId){
            ws.emit("start-sharing",{peerId:screenSharingId, roomId})
        }else{
            ws.emit("stop-sharing",{roomId})
        }
    },[screenSharingId,roomId])

    useEffect(()=>{
        if (!me) return;
       
        ws.on("user-joined", ({ peerId, userName: name, role: roleData,mute }) => {
            if (!stream){
                if(role === "Teacher"){
                    const canvas = document.createElement('canvas');
                    setStream(canvas.captureStream(30))
                    return
                }
                return
            }

            const call = me.call(peerId, (newDeviceStream)?newDeviceStream:stream, {
                metadata: {
                    userName,
                    role,mute
                },
            });

            call.on("stream", (peerStream) => {
                 // console.log('get the joined users stream')
                dispatch(addPeerStreamAction(peerId, peerStream));
            });

            dispatch(addPeerNameAction(peerId, name, roleData, mute));
            
        });

        me.on("call", (call) => {
            // console.log('2.we as a new joined user answer call from existing user')
            const { userName,role,mute } = call.metadata;
            
            dispatch(addPeerNameAction(call.peer, userName, role, mute));
            
            if(recon){
                try{
                    navigator.mediaDevices.getUserMedia({video:{
                        deviceId: initializeVideoDevice,
                        width: { exact: 1080 },
                        height: { exact: 720 },
                        frameRate: { max: 30 },
                    }, audio:true }).then(async (stream)=>{
                        // stream.getAudioTracks()[0].enabled = false;
                        call.answer(stream)
                        const videoTrack:any = stream.getTracks().find(track=>track.kind === 'video');
                        Object.values(me?.connections).forEach((connection:any)=>{
                            connection = connection.slice(connection.length - 1)
                            let data:any = connection[0];
                            if(connection.length > 1){
                                data = connection[connection.length - 1];
                            }
                            data.peerConnection.getSenders().find((sender:any)=>sender.track.kind === "video").replaceTrack(videoTrack).catch((err:any)=>{
                                console.log(err);
                            })
                        })
                    })
                }catch(error){
                    console.log(error)
                    console.log('failed to get local stream')
                }
            }else{
                call.answer(stream)
            }
            call.on("stream", (peerStream) => {
                dispatch(addPeerStreamAction(call.peer, peerStream));
            });
        });

       
        // return () => {
        //     ws.off("user-joined");
        // };

    },[me,stream,userName,newDeviceStream,role,recon])
    console.log({peers})
    return (
        <RoomContext.Provider value={{ 
            stream,
            screenStream,
            peers,
            videoDevices,
            audioOutputDevices,
            audioInputDevices,
            shareScreen,
            muteAudio,
            switchDevices,
            roomId,
            setRoomId,
            screenSharingId,
            connectionLost,
            soundOn,
            toggleSidebar,
            optionSidebar,
            newDeviceStream,
            exitRoom,
            closeClass,
            classStatus,
            alertShow,
            alertNote,
            getAuth,
            setAlertShow,
            setAlertNote,
            isLogin,
            muteAll,
            setMuteAll,
            teacherJoin,
            setTeacherJoin,
            getDevices,
            setInitialVideoDevice,
            initializeStream,
            reinitializeStream
        }}>{children}</RoomContext.Provider>
    );
};