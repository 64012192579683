import collect from "collect.js";
import { createContext, useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid"


interface Props {
    children: React.ReactNode;
}

interface UserValue {
    userId:string
    userName:string
    setUsername: (userName:string)=>void
    setUserData: (data:any)=>void
    user:any
    role:string
    classInfo:any
    setClassInfo: (classData:any)=>void
}

export const UserContext = createContext<UserValue>({
    userId:"",
    userName:"",
    setUsername: (userName) => {},
    setUserData: (data) => {},
    user: {},
    role:"",
    classInfo:{},
    setClassInfo: (classData) => {},
});


export const UserProvider : React.FC<Props> = ({children})=>{
    const [userId] = useState(localStorage.getItem("userId") || uuidV4());
    // const [userId] = useState(uuidV4());
    const [userName, setUsername] = useState(
        localStorage.getItem("userName") || ""
    );
    const [user, setUser] = useState({});
    const [role, setRole] = useState<string>("")
    const [classInfo, setClassInfo] = useState({});

    const setUserData = (data:any) =>{
        setUser(data)
        let roleData = ""
        if(data.hasOwnProperty('roles')){
            collect(data.roles).contains((value:string) =>{
                if(value === 'Admin'){
                    roleData = 'Admin'
                    return true
                } else
                if(value === 'Teacher'){
                    roleData = 'Teacher'
                    return true
                } else
                if(value === 'Senior Teacher'){
                    roleData = 'Teacher'
                    return true
                }
            })
        } else {
            roleData = "Student"
        }
        if(data.hasOwnProperty('child_id')){
            setUsername(data.child_name)
        }else{
            setUsername(data.name)
        }
        setRole(roleData)
    }

    useEffect(() => {
        localStorage.setItem("userName", userName);
    }, [userName]);

    useEffect(() => {
        localStorage.setItem("userId", userId);
    }, [userId]);

    return (
        <UserContext.Provider value={{ userId, userName, setUsername, setUserData,user,role,classInfo,setClassInfo }}>
            {children}
        </UserContext.Provider>
    );
}