import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { RoomContext } from "../context/RoomContext";

export const OptionSidebar: React.FC<{ open:boolean,onClick: () => void,defaultAudioOutput:string,defaultAudioInput:string}> = ({open,onClick,defaultAudioOutput,defaultAudioInput}) => {
    const {videoDevices,audioOutputDevices,audioInputDevices,switchDevices} = useContext(RoomContext)

    const [selectedAudioOutput,setAudioOutput] = useState<string>("");
    const [selectedAudioInput,setAudioInput] = useState<string>("");
    const [selectedVideoInput,setVideoInput] = useState<string>("");

    useEffect(()=>{
        setAudioOutput(defaultAudioOutput)
        setAudioInput(defaultAudioInput)
       
    },[defaultAudioInput,defaultAudioOutput])

    // const handleAudioInputChange = async (deviceId: string) => {
    //     handleAudioOutputChange(selectedAudioOutput)
    //     setAudioInput(deviceId)
    //     try {
    //         switchDevices(selectedVideoInput,deviceId)
    //     } catch (error) {
    //         console.error("Error setting audio input device:", error);
    //     }
    // };

    const handleAudioOutputChange = async (deviceId: string) => {
        const OtherVideoElement = await document.getElementById("other-video") as HTMLVideoElement
        setAudioOutput(deviceId)
        if(OtherVideoElement){
            try {
                if (OtherVideoElement && "setSinkId" in OtherVideoElement) {
                await(OtherVideoElement as any).setSinkId(deviceId)
                } else if (OtherVideoElement && "mozSetSinkId" in OtherVideoElement) {
                // For Firefox
                await (OtherVideoElement as any).mozSetSinkId(selectedAudioOutput)
                } else {
                console.error("Setting audio output device not supported");
                }
            } catch (error) {
                console.error("Error setting audio output device:", error);
            }
        }
    };

    const handleChangeVideo = async (deviceId: string) => {
        handleAudioOutputChange(selectedAudioOutput)
        setVideoInput(deviceId)
        try {
            switchDevices(deviceId,selectedAudioInput)
        } catch (error) {
            console.error("Error setting video input device:", error);
        }
        
    }
    return (
        <>
            <div className="relative z-10" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
            <div className={
                classNames(" inset-0 bg-gray-500 bg-opacity-75 transition-opacity ease-in-out duration-500",{
                    "fixed":open,
                    "hidden":!open
                })
            }></div>
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                    <div className={
                                        classNames("pointer-events-auto relative w-screen max-w-md transform transition ease-in-out duration-500 sm:duration-700",{
                                            "translate-x-0":open,
                                            "translate-x-full":!open
                                        })
                                    }>
                        <div className={
                                        classNames("absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4 ease-in-out duration-500",{
                                            "opacity-100":open,
                                            "opacity-0":!open
                                        })
                                    }>
                            <button type="button" className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" onClick={onClick}>
                            <span className="sr-only">Close panel</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                            </button>
                        </div>

                        <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                            <div className="px-4 sm:px-6 ">
                            <h2 className="text-base font-semibold leading-6 text-gray-900 border-b-2 pb-5 border-black" id="slide-over-title">Device Settings</h2>
                            </div>
                            <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                <div className="text-black flex w-auto flex-col mb-3">
                                    <label className='mr-2 font-semibold'>Video Source :</label>

                                    <select className='text-black' name="cars" id="cars" onChange={(event) => handleChangeVideo(event.target.value)}>
                                        {
                                            Object.values(videoDevices as object).map((item) => (
                                                <option value={item.deviceId} key={item.deviceId}>{item.label}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                {/* close audio changes */}
                                {/* <div className="text-black flex w-auto flex-col mb-3">
                                    <label className='mr-2 font-semibold'>Audio Input Source :</label>

                                    <select className='text-black' name="cars" id="cars" onChange={(event) => handleAudioInputChange(event.target.value)}>
                                        {
                                            Object.values(audioInputDevices as object).map((item) => (
                                                <option value={item.deviceId} key={item.deviceId}>{item.label}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="text-black flex w-auto flex-col mb-3">
                                    <label className='mr-2 font-semibold'>Audio Output Source :</label>

                                    <select name="cars" id="cars" onChange={(event) => handleAudioOutputChange(event.target.value)}>
                                        {
                                            Object.values(audioOutputDevices as object).map((item) => (
                                                <option value={item.deviceId} key={item.deviceId}>{item.label}</option>
                                            ))
                                        }
                                    </select>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}