import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RoomProvider } from './context/RoomContext';
import { Home } from './pages/Home';
import { Room } from './pages/Room';
import { UserProvider } from './context/UserContext';
import { ChatProvider } from './context/ChatContext';
import { ToastContainer} from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  
  <BrowserRouter>

    <UserProvider>
      <RoomProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/room/:id" element={<ChatProvider>  <ToastContainer/><Room /></ChatProvider>} />
        </Routes>
      </RoomProvider>
    </UserProvider>
    </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
