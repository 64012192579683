// import collect from "collect.js";
// import { useContext, useEffect } from "react";
// import { RoomContext } from "../../context/RoomContext";
// import { UserContext } from "../../context/UserContext";
// import { PeerState } from "../../reducers/peersReducer";
import { VideoPlayer } from "../VideoPlayer";


export const StudentView: React.FC<{ videoSource:any,peerArray:any,userName:string}> = ({videoSource,peerArray,userName}) => {
    // const {teacherJoin,setTeacherJoin} = useContext(RoomContext)
    // const {role} = useContext(UserContext)
    // useEffect(()=>{
    //     var Teacher = collect(peerArray).where('role','Teacher').first()
    //     if(Teacher){
    //         setTeacherJoin(true)
    //     }else{
    //         setTeacherJoin(false)
    //     }
    // },[peerArray,setTeacherJoin])
    return (<>
            {/* student only can see teacher and their own screen small */}
            {/* {teacherJoin && (<div>
                {Object.values(peerArray as PeerState).filter((peer) => !!peer.stream ).map((peer) => {
                    if(role === "Student" && peer.role === "Teacher"){
                        return (<div>
                            <div className="absolute w-full h-full top-0">
                                <VideoPlayer id={'other-video'} stream={peer.stream} mute={false} />
                            </div>
                        </div>
                        )
                    }else{
                        return
                    }
                })}
            </div>)}
           
            {!teacherJoin && (
                <div className="flex flex-col min-h-screen items-center justify-center">
                    <svg className="animate-spin -ml-1 mr-3 h-20 w-20 text-sky-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg> 
                    <div className="mt-5">
                        Waiting for Teacher ...
                    </div>
                </div>
            )} */}
            
            <div className="absolute w-full h-full top-0">
                <VideoPlayer id={'video'} stream={videoSource} mute={true} />
            </div>
            {/* is the children screen mute ? what will they hear */}

            {/* <div className="mb-5 ml-5 absolute  bottom-0 left-0 bg-white bg-opacity-75 border pt-2 rounded-md">
                <div className="h-full w-36 flex flex-shrink-0 flex-col mx-2 ">
                    <VideoPlayer id={'video'} stream={videoSource} mute={true} />
                    <div className='flex justify-center py-2 mt-2'>
                        {userName}
                    </div>
                </div>  
            </div> */}
            </>
        );
}