import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Join } from "../components/Join";
import axios from "axios"

export const Home = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    useEffect(()=>{
        if (token) {
            const url = process.env.REACT_APP_AUTH_API as string
            const tokenData:string = `Bearer ${token}` 
            axios.get( url ,{ headers: { 'Authorization': tokenData} }).then( function(response){
                console.log(response.data.data)
                // setUserData(response.data.data)
                // setIsLogin(true)
            }).catch(function(error){
                console.log(error.response.data.message)
            })
        }
    },[token])
    return (
        <div className="App flex items-center justify-center w-screen h-screen">
            <Join/> 
        </div>
    );
}