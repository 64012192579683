import classNames from "classnames";
import React from "react";

interface ButtonProps {
    children: React.ReactNode
    testId?: string
    onClick?:() => void
    className: string
    type?:"submit"|"button"|"reset"
}

export const Button: React.FC<ButtonProps> = ({ children , onClick , testId , className, type = "submit" }) => {
    return (
        <button
            type={type}
            data-testid = {testId}
            onClick={onClick}
            className={classNames('bg-sky-400 rounded-lg hover:bg-sky-600 text-white',className)}
        >
            {children}
        </button>
    )
}