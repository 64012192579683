import { createContext, useEffect, useReducer } from "react";
import { chatReducer, ChatState } from "../reducers/chatReducer";
import { IMessage } from '../types/chat';
import { addHistoryAction, addMessageAction, toggleChatAction } from '../reducers/chatActions';
import { ws } from "../ws";


interface Props {
    children: React.ReactNode;
}

interface ChatsValue {
    chats:ChatState
    sendMessage: (message:string, roomId:string, author:string)=>void
    toggleChat: () => void
}

export const ChatContext = createContext<ChatsValue>({
    chats: {
        messages:[],
        isChatOpen: false,
    },
    sendMessage: (message:string, roomId:string, author:string)=>{},
    toggleChat:()=>{}
});

export const ChatProvider : React.FC<Props> = ({children})=>{
    const [chats, chatDispatch] = useReducer(chatReducer, {
        messages:[],
        isChatOpen: false
    })

    const sendMessage = (message:string, roomId:string, author:string) => {
        const messageData: IMessage = {
            content:message,
            timestamp: new Date().getTime(),
            author
        }
        chatDispatch(addMessageAction(messageData))
        ws.emit("send-message",roomId,messageData)
    }

    const addMessage = (message:IMessage) =>{
        console.log("new message", message)
        chatDispatch(addMessageAction(message))
    }

    const addHistory = (messages:IMessage[]) => {
        chatDispatch(addHistoryAction(messages))
    }

    const toggleChat = () =>{
        chatDispatch(toggleChatAction(!chats.isChatOpen))
    }

    useEffect(()=>{
        ws.on("add-message", addMessage)
        ws.on("get-messages", addHistory)

        return ()=>{
            ws.off("add-message")
            ws.off("get-messages")
    
        }
    },[])
    return (
        <ChatContext.Provider value={{chats,sendMessage,toggleChat}}>{children}</ChatContext.Provider>
    )
}