import collect from "collect.js";
import { useContext, useEffect, useState } from "react";
import { RoomContext } from "../../context/RoomContext";
import { UserContext } from "../../context/UserContext";
import { PeerState } from "../../reducers/peersReducer";
import { ws } from "../../ws";
// import { Tooltip } from "../common/Tooltip";
// import { MuteAllChildrenButton } from "../MuteAllChildrenButton";
// import { MuteButton } from "../MuteButton";
import { MuteCenterVideoButton } from "../MuteCenterVideoButton";
import { VideoPlayer } from "../VideoPlayer";

export const TeacherView: React.FC<{ videoSource:any,peerArray:any,userName:string,roomId:string}> = ({videoSource,peerArray,userName,roomId}) => {
    const {muteAll,setMuteAll,soundOn,muteAudio} = useContext(RoomContext)
    const [centerVideoSource, setVideoSource] = useState<MediaStream>();
    const [videoKey, setVideoKey] = useState<number>();
    const [lastMute, setLastMute] = useState<any>();
    const [degree, setDegree] = useState<number>(0);
    const [centerDegree, setCenterDegree] = useState<number>(0);
    const [orientation, setOrientation] = useState<string>('horizontal');
    const {role} = useContext(UserContext)

    const copyVideoSource = (clickedStream:any,clickedType:string,key?:number) => {
        if(clickedType === 'others'){
            setVideoKey(key)
            const video = document.getElementById(`other-video-${key}`)  as HTMLVideoElement;
            const vertical = collect(video.classList).contains('w-48')
            // const videoDeg = 
            const computedStyle = window.getComputedStyle(video);
            const rotate = computedStyle.getPropertyValue('rotate');
           
            if(rotate !== 'none' ||'0deg'){
                setCenterDegree(parseInt(rotate))
            }else{
                setCenterDegree(0)
            }
            if(vertical){
                setOrientation('vertical')
            }else{
                setOrientation('horizontal')
            }
            setLastMute(video.muted)
            video.muted = true
            setVideoSource(clickedStream)
            
        } 
        if(clickedType === "self" && !key ){
            const video = document.getElementById(`other-video-${videoKey}`)  as HTMLVideoElement;
            if(!video){
                return
            }
            video.muted = lastMute
            setVideoSource(clickedStream)
        }
    };

    const muteSelectedVideo = (peerId:string,key:number) => {
        const video = document.getElementById(`other-video-${key}`)  as HTMLVideoElement;
        ws.emit('mute-selected',{ roomId : roomId, peerId:peerId, mute:!video.muted })
    }

    const muteAllStudent = ()=>{
        setMuteAll(!muteAll)
        ws.emit('mute-all-student',{ roomId : roomId,mute:muteAll})
    }

    const rotateScreen = (key:number) =>{
        const video = document.getElementById(`other-video-${key}`)  as HTMLVideoElement;
        const frame = document.getElementById(`other-video-frame${key}`)  as HTMLElement;
        const computedStyle = window.getComputedStyle(video);
        const rotate = computedStyle.getPropertyValue('rotate');
        const rotateNumber = parseInt(rotate)
        if(Number.isNaN(rotateNumber) || 0 ){
            video.classList.remove('w-full')
            video.classList.remove('object-cover')
            video.classList.add('w-48')
            video.classList.add('object-contain')
            video.style.rotate = `90deg`;
            frame.classList.remove('2xl:h-full')
            frame.classList.remove('lg:h-full')
        }else{
            if (rotateNumber < 270){
                video.style.rotate = `${rotateNumber+90}deg`;
                if(rotateNumber+90 === 90 ||rotateNumber+90 === 270){
                    video.classList.remove('w-full')
                    video.classList.remove('object-cover')
                    video.classList.add('w-48')
                    video.classList.add('object-contain')
                    frame.classList.remove('2xl:h-full')
                    frame.classList.remove('lg:h-full')
                }else{
                    video.classList.remove('w-48')
                    video.classList.remove('object-contain')
                    video.classList.add('w-full')
                    video.classList.add('object-cover')
                    frame.classList.add('2xl:h-full')
                    frame.classList.add('lg:h-full')
                }
            }else{
                video.classList.remove('w-48')
                video.classList.remove('object-contain')
                video.classList.add('w-full')
                video.classList.add('object-cover')
                video.style.rotate = `0deg`;
                frame.classList.add('2xl:h-full')
                frame.classList.add('lg:h-full')
            }
        }
    }
    
    useEffect(() => {
        const detectMute = collect(peerArray).where('role','!==','Teacher').where('mute',false).first()
        if(detectMute){
            setMuteAll(false)
        }else{
            setMuteAll(true)
        }
    }, [peerArray,setMuteAll])
    
    return (<>
            <div className='flex grow justify-center'>
                <div className='w-full h-full grid grid-cols-4 gap-4 gap-y-8'>
                {Object.values(peerArray as PeerState).filter((peer) => !!peer.stream).map((peer,key) => {
                    if(role==="Teacher" && peer.role==="Student"){
                        return (
                            <div id={'other-video-frame'+key} className='relative border-4 h-52 lg:h-full flex justify-center 2xl:h-full'>
                                <VideoPlayer id={'other-video-'+key} stream={peer.stream} mute={peer.mute} onClick={() => copyVideoSource(peer.stream,'others',key)}/>
                                <div className='flex justify-center py-2 absolute bottom-0 w-full -mb-10'>
                                    {peer.userName}
                                </div>
                                <div className="absolute bottom-0 right-0 mb-2 mr-4">
                                    <button className="bg-opacity-75 rounded-full bg-sky-500 text-white p-2" onClick={() => rotateScreen(key)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3" />
                                        </svg>
                                    </button>
                                </div>
                                {/* <div className="absolute bottom-0 right-0 mb-2">
                                    <MuteCenterVideoButton onClick={() => muteSelectedVideo(peer.peerId as any,key)} mute={peer.mute as any} />
                                </div> */}
                            </div>
                        )
                    }
                    if(role==="Admin"){
                        return (
                            <div className='relative border-4'>
                                <VideoPlayer id={'other-video-'+key} stream={peer.stream} mute={peer.mute} onClick={() => copyVideoSource(peer.stream,'others',key)}/>
                                <div className='flex justify-center py-2'>
                                    {peer.userName}
                                </div>
                                <div className="absolute bottom-0 right-0 mb-2">
                                    <MuteCenterVideoButton onClick={() => muteSelectedVideo(peer.peerId as any,key)} mute={peer.mute as any} />
                                </div>
                            </div>
                        )
                    }else{
                        return
                    }
                })}
                </div>
            </div>
            {/* center video when student video clicked */}
            {centerVideoSource  && 
            <div className="fixed w-full h-full bg-white flex items-center">
                <VideoPlayer videoWidth="full" degree={centerDegree} orientation={orientation} id={'center-video'} stream={centerVideoSource} mute={false} onClick={() => copyVideoSource(undefined,'self')} />
            </div>
            }
            {/* my screen */}
            { role === 'Admin' &&<div className="mb-5 ml-20 fixed bottom-0 left-0 bg-white border pt-2 rounded-md z-30">
                 <div className="h-full w-36 flex flex-shrink-0 flex-col mx-2">
                    <VideoPlayer  id={'video'} stream={videoSource} mute={true} onClick={() => copyVideoSource(undefined,'self')}/>
                    <div className='flex justify-center py-2 mt-2'>
                        {userName}
                    </div>
                </div>
            </div> }
                {/* <div className="fixed left-0 bottom-0 ml-2 mb-5 z-30">
                    <MuteButton onClick={muteAudio} mute={soundOn} />
                </div> */}
            {/* <div className="fixed bottom-0 right-0 mb-5 mr-2 z-30">
                <div className="group relative flex">
                    <MuteAllChildrenButton onClick={() => muteAllStudent()} mute={muteAll} />
                    <span className="w-20 -mt-14 absolute top-2 right-0 scale-0 transition-all rounded bg-gray-800 p-2 bg-opacity-75 text-white group-hover:scale-100 ">Mute All</span>
                </div>
                    
                
            </div> */}
        </>
        );
}