import { Button } from "./common/Button";

const backToHome = () =>{
    window.location.href = process.env.REACT_APP_MMLIVE as string
}
export const GoBackPortalButton: React.FC = () => {
    return (
            <Button className="mx-2 pr-1 py-1 bg-opacity-50 border-2 border-sky-500 uppercase font font-semibold" onClick={backToHome} testId="close-class-button">
                <div className="flex">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                    Go to Portal
                </div>
            </Button>
        );
}