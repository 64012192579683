import classNames from "classnames";

export const Alert: React.FC<{ open:boolean, note:string}> = ({open,note}) => {
    return (
            <div className={classNames("bg-yellow-100 rounded-lg p-4 mb-4 text-sm text-yellow-700 transform transition ease-in-out delay-300", {
                "flex translate-x-0":open,
                "hidden translate-x-72":!open 
            })} role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>

                <div>
                    <span className="font-medium">{note}</span> 
                </div>
            </div>
        );
}