import { IPeer } from "../types/peer";

export const ADD_PEER_STREAM = "ADD_PEER_STREAM" as const;
export const ADD_PEER_NAME = "ADD_PEER_NAME" as const;
export const REMOVE_PEER_STREAM = "REMOVE_PEER_STREAM" as const;
export const REMOVE_ALL_PEER_STREAM = "REMOVE_ALL_PEER_STREAM" as const;
export const ADD_ALL_PEERS = "ADD_ALL_PEERS" as const;
export const ADD_MUTE = "ADD_MUTE" as const;
export const MUTE_ALL_PEER = "MUTE_ALL_PEER" as const;

export const addPeerStreamAction = (peerId:string,stream:MediaStream) => ({
    type:ADD_PEER_STREAM,
    payload:{ peerId,stream}
});

export const removePeerStreamAction = (peerId:string) => ({
    type:REMOVE_PEER_STREAM,
    payload:{peerId}
})

export const removeAllPeerStreamAction = () => ({
    type:REMOVE_ALL_PEER_STREAM
})

export const addPeerNameAction = (peerId:string,userName:string,role:string,mute:boolean) => ({
    type:ADD_PEER_NAME,
    payload:{ peerId,userName,role,mute}
});

export const addAllPeersAction = (peers:Record<string, IPeer>) => ({
    type:ADD_ALL_PEERS,
    payload:{ peers}
})

export const addPeerMuteAction = (peerId:string,mute:boolean) => ({
    type:ADD_MUTE,
    payload:{peerId,mute}
});

export const addMuteAllPeerAction = (mute:boolean) => ({
    type:MUTE_ALL_PEER,
    payload:{mute}
});