import { Button } from "./common/Button";

const backToHome = () =>{
    window.location.href = process.env.REACT_APP_ELEARNING_PORTAL as string
}
export const BackButton: React.FC = () => {
    return (
            <Button className="mx-2 py-2 px-4 uppercase font font-semibold" onClick={backToHome} testId="close-class-button">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                </svg>

            </Button>
        );
}